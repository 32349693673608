import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { Accurait_PortalApi_Services_DirectoryApi_Models_Responses_GetInvitedUsersResponse as IGetInvitedUsersResponse } from '~/data-services/gen'
import { useApiRoles } from '~/composables/useAPI/useApiRoles'
import type {
  IInvitedUsers,
  IInviteUserRequest,
  ITokenDetail,
  IUserData,
} from '@settings'

export const useApiUserManagementInviteUser = () => {
  // To add a user, we need to set the user's role, use property `availableRoles`
  const apiRoles = useApiRoles()
  const organizationAdminId = computed(() => apiRoles.organizationAdminId.value)
  const availableRoles = computed(() => apiRoles.data.value)

  // Invalidate some query after adding new users
  const queryClient = useQueryClient()

  // Invite a user
  const inviteUser = useMutation({
    mutationFn: (body: IInviteUserRequest) => {
      const url = `/users`
      return fetchAPI(url, {
        method: 'POST',
        body,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getInvitedUsers'] })
    },
  })

  return {
    inviteUser,
    availableRoles,
    isLoadingRoles: apiRoles.isPending,
    organizationAdminId,
  }
}

export const useApiUserManagementGetInvited = () => {
  return useQuery<IInvitedUsers>({
    queryKey: ['getInvitedUsers'],
    queryFn: () =>
      fetchAPI<IGetInvitedUsersResponse>('/invitedUsers').then(
        ({ invitations }) => {
          return {
            invitations: invitations?.map<ITokenDetail>((invitation) => {
              const { token, status } = invitation
              const data: IUserData = JSON.parse(token.data ?? '{}')

              return {
                token: {
                  ...token,
                  data,
                },
                status,
              }
            }),
          }
        },
      ),
    // gcTime: 0,
    staleTime: 0,
  })
}

export const useApiUserManagementDeleteUser = (userId: string) => {
  const queryClient = useQueryClient()

  const removeUser = useMutation({
    mutationFn: () => {
      const url = `/users/${userId}`
      return fetchAPI(url, {
        method: 'DELETE',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUser'] })
      queryClient.removeQueries({ queryKey: ['getUser', userId] })
    },
  })

  return {
    removeUser,
  }
}
