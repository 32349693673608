import { useQuery } from '@tanstack/vue-query'
import type { IRoles } from './types'

export const useApiRoles = () => {
  const { data: me } = useAuth()

  const query = useQuery<IRoles[]>({
    queryKey: ['getRoles'],
    queryFn: () => fetchAPI<IRoles[]>('/roles'),
  })
  const myOrganizationRoles = computed(() => {
    if (!query.data.value || !me.value) {
      return [] // Return an empty array if roles or me are undefined or null
    }
    return query.data.value.filter((role) => {
      return me.value?.permissions?.some(
        (permission) => permission.roleId === role.id,
      )
    })
  })

  const isAdmin = computed(() => {
    return myOrganizationRoles.value.some(
      (role) =>
        role.product === 'leaseManager' && role.name === 'OrganizationAdmin',
    )
  })
  const isCompanyAdmin = computed(() => {
    return myOrganizationRoles.value.some(
      (role) =>
        role.product === 'leaseManager' &&
        (role.name === 'CompanyAdmin' || role.name === 'DivisionAdmin'),
    )
  })

  const findRoleById = (id: string): IRoles | undefined => {
    if (query.data.value) {
      return query.data?.value.find((role) => role.id === id)
    }
  }

  const organizationAdminId = computed(() => {
    return query.data?.value?.find((role) => role.name === 'OrganizationAdmin')
      ?.id
  })

  return {
    ...query,
    organizationAdminId,
    myOrganizationRoles,
    isAdmin,
    isCompanyAdmin,
    findRoleById,
  }
}
